<template>
  <v-menu
    class="quick-messages-menu"
    transition="slide-y-reverse-transition"
    offset-y
    top
    max-height="100%"
  >
    <template #activator="{ on }">
      <v-btn color="grey" text :style="activatorStyle" v-on="on">
        <v-icon v-text="`$vuetify.icons.plus`" />
      </v-btn>
    </template>
    <v-list class="quick-messages-menu__list">
      <!-- If there are *no* items -->
      <v-list-item v-if="!prewrittenTexts.length">
        <v-list-item-content>
          <v-list-item-title>No Quick Messages</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- If there *are* items -->
      <v-list-item
        v-for="item in prewrittenTexts"
        v-else
        :key="item.title"
        ripple
        @click="handleClick(item)"
      >
        <v-list-item-content>
          <v-list-item-title v-text="item.title" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { STORE_MESSAGES_QUERY } from "../graphql";

export default {
  name: "QuickMessagesMenu",
  props: {
    // A list of quick messages.
    // Ex:
    //  [
    //    { id: 1, title: "SLUG", message: "Dis a quick message." },
    //    { id: 2, title: "ANOTHAONE", message: "Dis another quick message." }
    //  ]
    items: {
      type: Array,
      default: () => [],
    },
    // Any CSS-acceptable height value
    // Ex:
    //  100%, 100vh, 32px, 4em, 10rem
    height: {
      type: String,
      default: "100%",
    },
  },

  data: () => ({
    prewrittenTexts: [],
  }),

  apollo: {
    prewrittenTexts: {
      query: STORE_MESSAGES_QUERY,
    },
  },

  computed: {
    // Computed style object for the activator
    activatorStyle() {
      return {
        height: this.height,
      };
    },
  },
  methods: {
    // Emit a "select" event when a quick message is clicked
    handleClick(message) {
      this.$emit("select", message);
    },
  },
};
</script>
